import React from 'react';
import { Script } from 'gatsby';
import AppLayout from '../components/AppLayout';
import GetConsultation from '../components/Consultation';



export default function Contact({location, navigate}) {
  return (
    <AppLayout location={location} navigate={navigate}>
    <GetConsultation h1={true} location={location}/>
    <Script type='text/javascript' id='hs-script-loader' async defer src='//js-na1.hs-scripts.com/22453439.js'></Script>
  </AppLayout>
  )
}


export const Head = () => (
    <>
      <title> Contact Targetiva Agency</title>
      <meta name="description" content="Contact Digital Marketing Agency Targetiva to get a FREE consultation today" />
    </>
  );